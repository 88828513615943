import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

class Menu extends Component {
    render() {
        return (
            <Fragment>
                {/* Logo */}
                <Link className="navbar-brand" to="/"> <img src={process.env.PUBLIC_URL + "/assets/img/logo-01.png"} alt="logo" /> </Link>
                {/* Menu */}
                <ul className="navbar-nav">
                    <li className="menu-item menu-item-has-children">
                        <Link to="/">Inicio</Link>
                    </li>
                    <li className="menu-item menu-item-has-children">
                        <Link to="#">Servicios</Link>
                        <ul className="submenu">
                            <li className="menu-item">
                                <Link to="/propiedadesenventa"> Propiedades en venta</Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/propiedadesenarriendo">Propiedades en arriendo</Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/terrenosenventa">Terrenos en venta</Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/suvdivisiones">Suvdivisiones Prediales</Link>
                            </li>
                        </ul>
                    </li>
                    <li className="menu-item menu-item-has-children mega-menu-wrapper">
                        <Link to="/nosotros">Nosotros</Link>
                    </li>
                    <li className="menu-item menu-item-has-children">
                        <Link to="/contacto">Contacto</Link>
                    </li>
                </ul>
            </Fragment>
        );
    }
}

export default Menu;