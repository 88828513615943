import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip, Dropdown, NavLink, } from 'react-bootstrap';
import listing from '../../../../data/listings.json';
import $ from 'jquery';
import 'magnific-popup'

// Gallery
const listinggallery = [
    { img: 'assets/img/listing-single/CalleVillagran/1.jpg' }
];

const gallerytip = (
    <Tooltip>
        Gallery
    </Tooltip>
);
const bedstip = (
    <Tooltip>
        Beds
    </Tooltip>
);
const bathstip = (
    <Tooltip>
        Bathrooms
    </Tooltip>
);
const areatip = (
    <Tooltip>
        Square Feet
    </Tooltip>
);



function Listingwrapper(props) {
    const [showMore, setShowMore] = useState(false);
  
    const showMoreToggle = () => {
      setShowMore(!showMore);
    };
  
    useEffect(() => {
      const popup = () => {
        $('.gallery-thumb').magnificPopup({
          type: 'image',
          gallery: {
            enabled: true
          },
        });
      };
  
      popup();
    }, []);

       {
        return (
            <div className="section listing-wrapper">
                <div className="container">
                    <div className="row">
                        {/* Listings Start */}
                        <div className="col-lg-8">
                            {/* Content Start */}
                            <div className="listing-content">
                                <h4>Casa Calle Villgran</h4>
                                <p>
                                Se trata de una propiedad con una amplia y cómoda área de living comedor, una cocina equipada con muebles, dos dormitorios cada uno con aire acondicionado y dos baños completos. La propiedad cuenta con un ascensor y entradas independientes por las calles Villagrán y Almirante La Torre, así como un portón eléctrico de cierre rápido y cámaras de seguridad para garantizar la tranquilidad y seguridad de sus habitantes.
                            <br/><br/>
                            Además, cuenta con oficinas y una sala de venta, lo que lo convierte en un lugar ideal para emprendimientos comerciales. La propiedad también dispone de un local comercial de 3 ambientes y estacionamiento para 5 a 6 vehículos.
                            <br/><br/>
                            Su ubicación es excelente, con acceso a transporte público justo en la puerta y cercanía a bencineras, supermercados, mini markets, colegios y otros servicios. En definitiva, se trata de una propiedad completa, moderna y funcional, ideal para vivir o emprender.                                     </p>
                                <div className="row">
                                    {listinggallery.map((item, i) => (
                                        <div key={i} className="col-md-12 mb-3">
                                            <Link to={item.img} className="gallery-thumb">
                                                <img src={process.env.PUBLIC_URL + "/" + item.img} alt="post" />
                                            </Link>
                                        </div>
                                    ))}
                                </div>

                            </div>
                            {/* Content End */}
        
                            {/* Price Range In the area End */}
                            <div className="section section-padding pt-0 acr-listing-features">
                                <h4>Caracteristicas</h4>
                                
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <div style={{paddingLeft:80}} className="listing-feature-wrapper">
                                            <div className="listing-feature">
                                                <i className="flaticon-picture" />
                                                <h6 className="listing-feature-label">Tipo</h6>
                                                <span className="listing-feature-value">Casa</span>
                                            </div>
                                            <div className="listing-feature">
                                                <i className="flaticon-bone" />
                                                <h6 className="listing-feature-label">Pet Friendly</h6>
                                                <span className="listing-feature-value">Si</span>
                                            </div>
                                            <div className="listing-feature">
                                                <i className="fas fa-shopping-basket" />
                                                <h6 className="listing-feature-label">Supermercado </h6>
                                                <span className="listing-feature-value">Si</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div style={{paddingLeft:80}} className="listing-feature-wrapper">
                                            <div className="listing-feature">
                                                <i className="flaticon-bathroom" />
                                                <h6 className="listing-feature-label">Baños</h6>
                                                <span className="listing-feature-value">2</span>
                                            </div>
                                            <div className="listing-feature">
                                                <i className="flaticon-pillow" />
                                                <h6 className="listing-feature-label">Dormitorios</h6>
                                                <span className="listing-feature-value">2</span>
                                            </div>
                                            <div className="listing-feature">
                                                <i className="fas fa-graduation-cap" />
                                                <h6 className="listing-feature-label">Colegio Cercano </h6>
                                                <span className="listing-feature-value">Si</span>
                                            </div>
                                        </div>
                                    </div>


                                </div>    
                                                      
                            </div>             
                            
                       
                    
                            {/* Similar Start */}
                           
                            {/* Similar End */}
                        </div>

                        <div className="col-lg-4">
                                <div className="sidebar sticky-sidebar">
                                    <div className="sidebar-widget">
                                            <h5 className='text-center'>¿Te interesó?</h5>
                                            <div className="text-center">             
                                                    <a href="https://api.whatsapp.com/send?phone=56934121497&text=Hola!! Necesito más información." >
                                                    <i className="fab fa-whatsapp" style={{ fontSize: "300%", color: "green" }}></i>
                                                    </a>
                                                    <h6>Escríbenos a <br/> nuestro WhatsApp </h6>
                                            </div>
                                    </div>
                                </div>
                         </div> 
                          

                        <div className="section section-padding">
                                <h4>Propiedades Similares</h4>
                                <div className="row">
                                    {/* Listing Start */}
                                    {listing.slice(0, 2).map((item, i) => (
                                        <div key={i} className="col-md-6">
                                            <div className="listing">
                                                <div className="listing-thumbnail">
                                                    <Link to={item.link}><img src={process.env.PUBLIC_URL + "/" + item.gridimg} alt="listing" /></Link>
                                                    <div className="listing-badges">
                                                    {
                                                        item.star === true ? <span className="listing-badge sale"> <i className="fas fa-star" /> </span> : ''
                                                    }
                                                    {
                                                        item.sale === true ? <span className="listing-badge sale">Disponible</span> : ''
                                                    }
                                                    {
                                                        item.sell === true ? <span className="listing-badge pending"> Vendida</span> : ''
                                                    }
                                                    {
                                                        item.rental === true ? <span className="listing-badge sale"> Disponible</span> : ''
                                                    }
                                                    </div>
                                
                                                </div>
                                                <div className="listing-body">
                                          
                                                    <h5 className="listing-title"> <Link to={item.link} title={item.title}>{item.title}</Link> </h5>
                                                    <span className="listing-price">${new Intl.NumberFormat().format((item.monthlyprice).toFixed(2))} <span>/ Mensual</span> </span>
                                                    <p className="listing-text">{item.text}</p>
                                                    <div className="acr-listing-icons">
                                                        <OverlayTrigger overlay={bedstip}>
                                                            <div className="acr-listing-icon">
                                                                <i className="flaticon-bedroom" />
                                                                <span className="acr-listing-icon-value">{item.beds}</span>
                                                            </div>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger overlay={bathstip}>
                                                            <div className="acr-listing-icon">
                                                                <i className="flaticon-bathroom" />
                                                                <span className="acr-listing-icon-value">{item.bathrooms}</span>
                                                            </div>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger overlay={areatip}>
                                                            <div className="acr-listing-icon">
                                                                <i className="flaticon-ruler" />
                                                                <span className="acr-listing-icon-value">{new Intl.NumberFormat().format((item.area))} m2</span>
                                                            </div>
                                                        </OverlayTrigger>
                                                    </div>
                                                    <div className="listing-gallery-wrapper">
                                                        <Link to={item.link} className="btn-custom btn-sm secondary">Ver Detalles</Link>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    {/* Listing End */}
                                </div>
                            </div>
                        {/* Listings End */}
                        {/* Sidebar Start */}
                    
                        {/* Sidebar End */}
                    </div>
                </div>
            </div>
        );
    }
}

export default Listingwrapper;