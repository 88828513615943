import React, { Component, Fragment } from 'react';
import MetaTags from "react-meta-tags";
import Headertwo from '../../layouts/Headertwo';
import Footercontent from '../../layouts/Footercontent';
import Content from '../../sections/listing-details-v1/Terr4/Content';

class Carrizal extends Component {
    render() {
        return (
            <Fragment>
                <MetaTags>
                    <title>Negocios Inmobiliarios| Terrenos</title>
                    <meta
                        name="description"
                        content="#"
                    />
                </MetaTags>
                <Headertwo/>
                <Content/>
                <Footercontent/>
            </Fragment>
        );
    }
}

export default Carrizal;