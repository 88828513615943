import React, { Component } from 'react';
import Slider from 'react-slick';

class Banner extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.slider = React.createRef(); // Create a ref for the Slider component
    }

    next() {
        if (this.slider.current) { // Check if the slider ref is defined
            this.slider.current.slickNext(); // Call slickNext method if the slider ref is defined
        }
    }

    previous() {
        if (this.slider.current) { // Check if the slider ref is defined
            this.slider.current.slickPrev(); // Call slickPrev method if the slider ref is defined
        }
    }

    render() {
        const { property } = this.props;

        const settings = {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: false,
            fade: true,
        };

        return (
            <div className="banner banner-2 slider-no-padding">
                <div className="banner-item">
                    <Slider className="banner-slider" ref={this.slider} {...settings}>
                        {property.imagenes.map((image, index) => (
                            <div key={index}>
                                <div className="banner-inner bg-center dark-overlay" style={{ backgroundImage: `url(/${image.img_path})` }} />
                            </div>
                        ))}
                    </Slider>


                    <div className="acr-listing-details">
                        <div className="acr-listing-section">
                            <div className="acr-listing-section-body">
                                <div className="acr-listing-section-price">
                                <span>{property.servicio} - </span>
                                    <span>{property.tipo}</span>
                                    <h3>${new Intl.NumberFormat().format(property.precio)}</h3>
                                </div>
                            </div>
                        </div>
                        <div className="acr-listing-section">
                            <div className="acr-listing-section-body">
                                <h4>Direccion: {property.titulo}</h4>
                                <div className="acr-listing-icons">
                                    <div className="acr-listing-icon">
                                        <i className="flaticon-bedroom" />
                                        <span className="acr-listing-icon-value">{property.dormitorios}</span>
                                        <span>Dormitorios</span>
                                    </div>
                                    <div className="acr-listing-icon">
                                        <i className="flaticon-bathroom" />
                                        <span className="acr-listing-icon-value">{property.banos}</span>
                                        <span>Baños</span>
                                    </div>
                                    <div className="acr-listing-icon">
                                        <i className="flaticon-ruler" />
                                        <span className="acr-listing-icon-value">{property.area}</span>
                                        <span>M2</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="acr-arrows">
                    <i className="slider-prev fas fa-arrow-left slick-arrow" onClick={this.previous} />
                    <i className="slider-next fas fa-arrow-right slick-arrow" onClick={this.next} />
                </div>
            </div>
        );
    }
}

export default Banner;
