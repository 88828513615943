import React, { Fragment } from 'react';
import Banner from './Banner';
import Listingwrapper from './Listingwrapper';

const Content = ({ property }) => {
    console.log(property)
    return (
        <Fragment>
           <Banner property={property} />
            <Listingwrapper property={property} />
        </Fragment>
    );
}

export default Content;