import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import $ from 'jquery';
import 'magnific-popup';
import axios from 'axios';

const gallerytip = (
    <Tooltip>
        Gallery
    </Tooltip>
);
const bedstip = (
    <Tooltip>
        Beds
    </Tooltip>
);
const bathstip = (
    <Tooltip>
        Bathrooms
    </Tooltip>
);
const areatip = (
    <Tooltip>
        Square Feet
    </Tooltip>
);

function Listingwrapper({ property }) {
    const [showMore, setShowMore] = useState(false);
    const [properties, setProperties] = useState([]);
    const [similarProperties, setSimilarProperties] = useState([]);

    const showMoreToggle = () => {
        setShowMore(!showMore);
    };

    useEffect(() => {
        const popup = () => {
            $('.gallery-thumb').magnificPopup({
                type: 'image',
                gallery: {
                    enabled: true
                },
            });
        };
        popup();

        const fetchData = async () => {
            try {
                const response = await axios.get('https://backend-inmobiliaria-dev-gbpd.1.us-1.fl0.io/propiedades');
                // Obtener todas las propiedades
                setProperties(response.data);
                // Obtener solo las dos primeras propiedades para propiedades similares
                setSimilarProperties(response.data.slice(0, 2));
                console.log(response.data.slice(0, 2));
            } catch (error) {
                console.error('Error fetching properties:', error);
            }
        };
        fetchData();
    }, []);



    return (
        <div className="section listing-wrapper">
            <div className="container">
                <div className="row">
                    {/* Listings Start */}
                    <div className="col-lg-8">
                        {/* Content Start */}
                        <div className="listing-content">
                            <div>
                                <h4>{property.titulo}</h4>
                                <p>{property.descripcion}</p>

                                <div className="row">
                                    {property.imagenes.map((image, i) => (
                                        <div key={i} className="col-md-6 mb-3">
                                            <a href={`/${image.img_path}`} className="gallery-thumb">
                                                <img src={`/${image.img_path}`} alt="post" />
                                            </a>
                                        </div>
                                    ))}
                                </div>

                                <div className="section section-padding pt-0 acr-listing-features">
                                    <h4>Caracteristicas</h4>
                                    <div className="row">
                                        <div className="col-lg-6 col-sm-12">
                                            <div className="listing-feature">
                                                <i className="flaticon-picture" />
                                                <h6 className="listing-feature-label">Tipo</h6>
                                                <span className="listing-feature-value">{property.tipo}</span>
                                            </div>
                                            <div className="listing-feature">
                                                <i className="flaticon-bone" />
                                                <h6 className="listing-feature-label">Pet Friendly</h6>
                                                <span className="listing-feature-value">{property.petFriendly}</span>
                                            </div>
                                            <div className="listing-feature">
                                                <i className="fas fa-shopping-basket" />
                                                <h6 className="listing-feature-label">Supermercado</h6>
                                                <span className="listing-feature-value">{property.supermercado}</span>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <div className="listing-feature">
                                                <i className="flaticon-bathroom" />
                                                <h6 className="listing-feature-label">Baños</h6>
                                                <span className="listing-feature-value">{property.banos}</span>
                                            </div>
                                            <div className="listing-feature">
                                                <i className="flaticon-pillow" />
                                                <h6 className="listing-feature-label">Dormitorios</h6>
                                                <span className="listing-feature-value">{property.dormitorios}</span>
                                            </div>
                                            <div className="listing-feature">
                                                <i className="fas fa-graduation-cap" />
                                                <h6 className="listing-feature-label">Colegio Cercano</h6>
                                                <span className="listing-feature-value">{property.colegioCercano}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="section section-padding">
                            <h4>Propiedades Similares</h4>
                            <div className="row">
                                {/* Listing Start */}
                                {similarProperties.map((item, i) => (
                                    <div key={i} className="col-md-6">
                                        <div className="listing">
                                            <div className="listing-thumbnail">
                                                <Link to={item.link}><img src={process.env.PUBLIC_URL + "/" + item.img_principal} alt="listing" /></Link>
                                                <div className="listing-badges">
                                                    {
                                                        item.star === true ? <span className="listing-badge sale"> <i className="fas fa-star" /> </span> : ''
                                                    }
                                                    {
                                                        item.sale === true ? <span className="listing-badge sale">Disponible</span> : ''
                                                    }
                                                    {
                                                        item.sell === true ? <span className="listing-badge pending"> Vendida</span> : ''
                                                    }
                                                    {
                                                        item.rental === true ? <span className="listing-badge sale"> Disponible</span> : ''
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {/* Listing End */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="sidebar sticky-sidebar">
                            <div className="sidebar-widget">
                                <h5 className='text-center'>¿Te interesó?</h5>

                                <div className="text-center">
                                    <a href="https://api.whatsapp.com/send?phone=56934121497&text=Hola!! Necesito más información." >
                                        <i className="fab fa-whatsapp" style={{ fontSize: "300%", color: "green" }}></i>
                                    </a>
                                    <h6>Escríbenos a <br /> nuestro WhatsApp </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Listingwrapper;
