import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

class Footercontent extends Component {
    render() {
        return (
            <Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 col-sm-12 footer-widget ">
                            <div className="footer-logo mx-auto">
                                <img src={process.env.PUBLIC_URL + "/assets/img/logo-01.png"} alt="NegociosInmobiliarios" />
                            </div>
                            <p className='text-center'>En nuestra inmobiliaria, trabajamos para encontrar la propiedad de tus sueños. Contáctanos y déjanos ayudarte en este importante paso de tu vida.</p>
                            <ul className="social-media mx-auto d-flex justify-content-center">
                                <li> <a href="https://www.facebook.com/negociosinmobiliariosla">
                                            <i className="fab fa-facebook" style={{ color: "#4267B2" }}></i> 
                                            </a> 
                                </li>
                                <li> <a href="https://www.instagram.com/negociosinmobiliarios_la/">
                                            <i className="fab fa-instagram"></i> 
                                            </a> 
                                </li>
                                <li> <a href="https://api.whatsapp.com/send?phone=+56934121497&text=Hola!! Necesito más información.">
                                            <i className="fab fa-whatsapp" style={{ color: "green" }}></i> 
                                            </a> 
                                </li>

                            </ul>
                        </div>
                        <div className="col-md-4 col-sm-6 footer-widget text-center">
                            <h5 className="widget-title">Servicios</h5>
                            <ul>
                                <li> <Link to="/propiedadesenventa">Propiedades en venta</Link> </li>
                                <li> <Link to="/propiedadesenarriendo">Propiedades en Arriendo</Link> </li>
                                <li> <Link to="/terrenosenventa">Terrenos en venta</Link> </li>
                                <li> <Link to="#"></Link> </li>
                            </ul>
                        </div>
                        <div className="col-md-4 col-sm-6 footer-widget text-center">
                            <h5 className="widget-title">Horarios</h5>
                            <ul>
                              {/*   <li> <Link to="#">Contacto</Link> </li>
                                <li> <Link to="#">Nosotros</Link> </li> */}
                                 <p className='text-center'>Lunes a Viernes <br></br> 09:00 - 13:30 <br></br>  15:30 - 18:30 </p>
                          </ul>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                            <a href='https://digtmo.com/'>
                            <p className="m-0">© 2023 by <span>digtmo.com</span></p>
                            </a> 
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Footercontent;