import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip, Dropdown, NavLink, } from 'react-bootstrap';
import listing from '../../../../data/listings.json';
import $ from 'jquery';
import 'magnific-popup'

// Gallery
const listinggallery = [
    { img: 'assets/img/listing-single/Haciendalascruces/2.jpg' },
    { img: 'assets/img/listing-single/Haciendalascruces/3.jpg' },
    { img: 'assets/img/listing-single/Haciendalascruces/4.jpg' },
    { img: 'assets/img/listing-single/Haciendalascruces/5.jpg' },
    { img: 'assets/img/listing-single/Haciendalascruces/6.jpg' },
    { img: 'assets/img/listing-single/Haciendalascruces/7.jpg' },
];

var urlActual = window.location.href;

console.log(urlActual)

const gallerytip = (
    <Tooltip>
        Gallery
    </Tooltip>
);
const bedstip = (
    <Tooltip>
        Beds
    </Tooltip>
);
const bathstip = (
    <Tooltip>
        Bathrooms
    </Tooltip>
);
const areatip = (
    <Tooltip>
        Square Feet
    </Tooltip>
);



function Listingwrapper(props) {
    const [showMore, setShowMore] = useState(false);
  
    const showMoreToggle = () => {
      setShowMore(!showMore);
    };
  
    useEffect(() => {
      const popup = () => {
        $('.gallery-thumb').magnificPopup({
          type: 'image',
          gallery: {
            enabled: true
          },
        });
      };
  
      popup();
    }, []);

       {
        return (
            <div className="section listing-wrapper">
                <div className="container">
                    <div className="row">
                        {/* Listings Start */}
                        <div className="col-lg-8">
                            {/* Content Start */}
                            <div className="listing-content">
                                <h4>Casa Hacienda las Cruces</h4>
                                <p className='text-justify'>
                                La casa que se arrienda se encuentra en el Condominio Hacienda Las Cruces y cuenta con todas las comodidades necesarias para una vida cómoda y tranquila. Tiene 2 dormitorios, cada uno con su propio baño, lo que brinda privacidad y comodidad para sus habitantes. Además, cuenta con un amplio living/comedor, una cocina completamente amoblada y equipada, lo que permite preparar alimentos de manera fácil y cómoda.
                                <br/> <br/>
                                El estacionamiento es una gran ventaja ya que permite tener un lugar seguro y exclusivo para guardar el vehículo. También cuenta con un patio con pasto, perfecto para disfrutar del aire libre y realizar actividades al aire libre.
                                <br/> <br/>
                                El precio de arriendo es de $420.000, con los gastos comunes incluidos, lo que hace que sea una opción muy atractiva para aquellos que buscan una casa cómoda y bien ubicada. En resumen, esta casa es una excelente opción para quienes buscan vivir en un lugar tranquilo, seguro y con todas las comodidades necesarias.
                                </p>
                                <div className="row">
                                    {listinggallery.map((item, i) => (
                                        <div key={i} className="col-md-6 mb-3">
                                            <Link to={item.img} className="gallery-thumb">
                                                <img src={process.env.PUBLIC_URL + "/" + item.img} alt="post" />
                                            </Link>
                                        </div>
                                    ))}
                                </div>

                            </div>
          
                            <div className="section section-padding pt-0 acr-listing-features">
                                <h4>Caracteristicas</h4>
                                
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <div style={{paddingLeft:80}} className="listing-feature-wrapper">
                                            <div  className="listing-feature">
                                                <i className="flaticon-picture" />
                                                <h6 className="listing-feature-label">Tipo</h6>
                                                <span className="listing-feature-value">Casa</span>
                                            </div>
                                            <div className="listing-feature">
                                                <i className="flaticon-bone" />
                                                <h6 className="listing-feature-label">Pet Friendly</h6>
                                                <span className="listing-feature-value">Si</span>
                                            </div>
                                            <div className="listing-feature">
                                                <i className="fas fa-shopping-basket" />
                                                <h6 className="listing-feature-label">Supermercado </h6>
                                                <span className="listing-feature-value">Si</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div style={{paddingLeft:80}} className="listing-feature-wrapper">
                                            <div className="listing-feature">
                                                <i className="flaticon-bathroom" />
                                                <h6 className="listing-feature-label">Baños</h6>
                                                <span className="listing-feature-value">2</span>
                                            </div>
                                            <div className="listing-feature">
                                                <i className="flaticon-pillow" />
                                                <h6 className="listing-feature-label">Dormitorios</h6>
                                                <span className="listing-feature-value">2</span>
                                            </div>
                                            <div className="listing-feature">
                                                <i className="fas fa-graduation-cap" />
                                                <h6 className="listing-feature-label">Colegio Cercano </h6>
                                                <span className="listing-feature-value">Si</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>                          
                            </div>                  
                    

                          
                      
                        </div>
              
                        <div className="col-lg-4">
                            <div className="sidebar sticky-sidebar">
                                <div className="sidebar-widget">
                                        <h5 className='text-center'>¿Te interesó?</h5>
                                        <div className="text-center">             
                                                <a href="https://api.whatsapp.com/send?phone=56934121497&text=Hola!! Necesito más información." >
                                                <i className="fab fa-whatsapp" style={{ fontSize: "300%", color: "green" }}></i>
                                                </a>
                                                <h6>Escríbenos a <br/> nuestro WhatsApp </h6>
                                        </div>
                                </div>
                            </div>
                        </div>    
                        

                                <div className="section section-padding">
                                <h4>Propiedades Similares</h4>
                                <div className="row">
                                    {/* Listing Start */}
                                    {listing.slice(0, 2).map((item, i) => (
                                        <div key={i} className="col-md-6">
                                            <div className="listing">
                                                <div className="listing-thumbnail">
                                                    <Link to={item.link}><img src={process.env.PUBLIC_URL + "/" + item.gridimg} alt="listing" /></Link>
                                                    <div className="listing-badges">
                                                    {
                                                        item.star === true ? <span className="listing-badge sale"> <i className="fas fa-star" /> </span> : ''
                                                    }
                                                    {
                                                        item.sale === true ? <span className="listing-badge sale">Disponible</span> : ''
                                                    }
                                                    {
                                                        item.sell === true ? <span className="listing-badge pending"> Vendida</span> : ''
                                                    }
                                                    {
                                                        item.rental === true ? <span className="listing-badge sale"> Disponible</span> : ''
                                                    }
                                                    </div>
                                
                                                </div>
                                                <div className="listing-body">
                                          
                                                    <h5 className="listing-title"> <Link to={item.link} title={item.title}>{item.title}</Link> </h5>
                                                    <span className="listing-price">${new Intl.NumberFormat().format((item.monthlyprice).toFixed(2))} <span></span> </span>
                                                    <p className="listing-text">{item.text}</p>
                                                    <div className="acr-listing-icons">
                                                        <OverlayTrigger overlay={bedstip}>
                                                            <div className="acr-listing-icon">
                                                                <i className="flaticon-bedroom" />
                                                                <span className="acr-listing-icon-value">{item.beds}</span>
                                                            </div>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger overlay={bathstip}>
                                                            <div className="acr-listing-icon">
                                                                <i className="flaticon-bathroom" />
                                                                <span className="acr-listing-icon-value">{item.bathrooms}</span>
                                                            </div>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger overlay={areatip}>
                                                            <div className="acr-listing-icon">
                                                                <i className="flaticon-ruler" />
                                                                <span className="acr-listing-icon-value">{new Intl.NumberFormat().format((item.area))} m2</span>
                                                            </div>
                                                        </OverlayTrigger>
                                                    </div>
                                                    <div className="listing-gallery-wrapper">
                                                        <Link to={item.link} className="btn-custom btn-sm green">Ver Detalles</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    {/* Listing End */}
                                </div>
                            </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default Listingwrapper;