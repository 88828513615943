import React, { Component } from 'react';
import MetaTags from "react-meta-tags";
import Headertwo from '../layouts/Headertwo';
import Footercontent from '../layouts/Footercontent';
import Content from '../sections/listing-details-v1/propiedad/Content';
import axios from 'axios';

class Propiedad extends Component {
    constructor(props) {
        super(props);
        this.state = {
            property: null,
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = async () => {
        try {
            const { id } = this.props.match.params;
            const response = await axios.get(`https://backend-inmobiliaria-dev-gbpd.1.us-1.fl0.io/propiedades/${id}`);
            this.setState({ property: response.data });
        } catch (error) {
            console.error('Error fetching property:', error);
        }
    };

    render() {
        return (
            <div>
                <MetaTags>
                    <title>Negocios Inmobiliarios | Propiedad</title>
                    <meta
                        name="description"
                        content="#"
                    />
                </MetaTags>
                <Headertwo />
                {this.state.property && <Content property={this.state.property} />}
                <Footercontent />
            </div>
        );
    }
}

export default Propiedad;
