import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip, Dropdown, NavLink, } from 'react-bootstrap';
import listing from '../../../../data/listings.json';
import $ from 'jquery';
import 'magnific-popup'

// Gallery
const listinggallery = [
    {
        img: 'assets/img/listing-single/laflorida/2.jpg',
    },
    {
        img: 'assets/img/listing-single/laflorida/3.jpg',
    },
    {
        img: 'assets/img/listing-single/laflorida/4.jpg',
    },
    {
        img: 'assets/img/listing-single/laflorida/5.jpg',
    }
];

const gallerytip = (
    <Tooltip>
        Gallery
    </Tooltip>
);
const bedstip = (
    <Tooltip>
        Beds
    </Tooltip>
);
const bathstip = (
    <Tooltip>
        Bathrooms
    </Tooltip>
);
const areatip = (
    <Tooltip>
        Square Feet
    </Tooltip>
);



function Listingwrapper(props) {
    const [showMore, setShowMore] = useState(false);
  
    const showMoreToggle = () => {
      setShowMore(!showMore);
    };
  
    useEffect(() => {
      const popup = () => {
        $('.gallery-thumb').magnificPopup({
          type: 'image',
          gallery: {
            enabled: true
          },
        });
      };
  
      popup();
    }, []);

       {
        return (
            <div className="section listing-wrapper">
                <div className="container">
                    <div className="row">
                        {/* Listings Start */}
                        <div className="col-lg-8">
                            {/* Content Start */}
                     
                            <div className="listing-content">
                                <h4>4 lotes en Sector Carrizal, a 6,4 KM de la ciudad de Florida.</h4>

                                <p>
                                En venta, se encuentran disponibles cuatro lotes de 5000 m2 cada uno en el sector Carrizal, a tan solo 6,4 km de la ciudad de Florida. Estos lotes ofrecen una excelente oportunidad para aquellos que buscan un espacio amplio y bien ubicado para construir su hogar o invertir en un proyecto.
                               </p>
                               <br/>
                               <p>
                               Cada lote cuenta con su propio rol, lo que brinda seguridad y tranquilidad en términos de propiedad. Además, se encuentran a orilla de camino, lo que facilita el acceso y proporciona comodidad para los residentes.
                               </p>
                               <br/>
                               <p>
                               El valor de cada lote es de $25.000.000, lo que los convierte en una excelente opción de inversión considerando su tamaño y ubicación privilegiada. Si estás buscando un terreno espacioso y listo para construir en un entorno tranquilo y cercano a la ciudad, estos lotes son perfectos para ti.
                               </p>
                                <div className="row">
                                    {listinggallery.map((item, i) => (
                                        <div key={i} className="col-md-6 mb-3">
                                            <Link to={item.img} className="gallery-thumb">
                                                <img src={process.env.PUBLIC_URL + "/" + item.img} alt="post" />
                                            </Link>
                                        </div>
                                    ))}
                                </div>

                            </div>
                            {/* Content End */}
        
                            {/* Price Range In the area End */}
                                    
                    
                            {/* Similar Start */}

                
                            {/* Similar End */}
                        </div>
                        {/* Listings End */}
                        {/* Sidebar Start */}
                        <div className="col-lg-4">
                            <div className="sidebar sticky-sidebar">
                                <div className="sidebar-widget">
                                    <h5>Agregados Recientemente</h5>
                                    {/* Listing Start */}
                                    {listing.filter(function (item) { return item.recent === true }).slice(0, 4).map((item, i) => (
                                        <div key={i} className="listing listing-list">
                                            <div className="listing-thumbnail">
                                                <Link to={item.link}><img src={process.env.PUBLIC_URL + "/" + item.gridimg} alt="listing" /></Link>
                                            </div>
                                            <div className="listing-body">
                                                <h6 className="listing-title"> <Link to={item.link} title={item.title}>{item.title}</Link> </h6>
                                                <span className="listing-price">${new Intl.NumberFormat().format((item.monthlyprice).toFixed(2))} <span></span> </span>
                                            </div>
                                        </div>
                                    ))}
                                    {/* Listing End */}
                                </div>
                            </div>
                        </div>
                        {/* Sidebar End */}
                    </div>
                </div>
            </div>
        );
    }
}

export default Listingwrapper;