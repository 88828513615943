import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import CalleVillagran from './components/Propiedades/CalleVillagran';
import SorVicenta from './components/Propiedades/SorVicenta';
import SantaAna from './components/Propiedades/SantaAna';
import Chacayal from './components/Propiedades/Terrenos/Chacayal'
import Haciendalascruces from './components/Propiedades/Haciendalascruces';
import LasOrquideas from './components/Propiedades/Lasorquideas';
import VillaLasIslas from './components/Propiedades/VillaLasIslas';
import CasaEsquinaSorVicenta from './components/Propiedades/CasaEsquinaSorVicenta';
import HomeStudio from './components/Propiedades/HomeStudio';
import SorVicenta2 from './components/Propiedades/SorVicenta2';
import CaminoAntuco from './components/Propiedades/Antuco';
import CerroColorado from './components/Propiedades/Terrenos/CerroColorado';
import CondominioCordillera from './components/Propiedades/CondominioCordillera';
import SantaAna2 from './components/Propiedades/SantaAna2';
import SantaAna3 from './components/Propiedades/SantaAna3';
import CasaMiradorOriente from './components/Propiedades/CasaMiradorOriente';
import Bodegas from './components/Propiedades/Bodegas';
import ElPeral from './components/Propiedades/Terrenos/ElPeral';
import DepartamentoLientur from './components/Propiedades/Lientur';
import Carrizal from './components/Propiedades/Terrenos/Carrizal';
import galilea from './components/Propiedades/galilea';
import quillayes from './components/Propiedades/quillayes'
import villagenesis from './components/Propiedades/villagenesis';
import gabrielamistral from './components/Propiedades/gabrielamistral';
import torrevaldivia from './components/Propiedades/torrevaldivia';
import ClaudioArrau from './components/Propiedades/claudioarrau';
import VillaRetiroSur from './components/Propiedades/villaRetiroSur';
import VillaEspana from './components/Propiedades/villaEspana';
import VillaAntuco from './components/Propiedades/villaAntuco';
import Ohiggins from './components/Propiedades/Ohiggins';
import casatodoslossantos from './components/Propiedades/villatodoslosantos';
import CaminoCerroColorado from './components/Propiedades/Terrenos/CaminoCerroColorado';
import LagunaVerde from './components/Propiedades/LagunaVerde';
import Lacastellana from './components/Propiedades/LaCastellana';
import LosQuillayes from './components/Propiedades/LosQuillayes';
import SantaAdriana from './components/Propiedades/Terrenos/Santa Adriana';
import LosRobles from './components/Propiedades/Terrenos/LosRobles';
import LosQuillayes2 from './components/Propiedades/LosQuillayes2';
import CalleMendoza from './components/Propiedades/CalleMendoza';
import CondoinioGabrielaMistral from './components/Propiedades/CondominioGabrielaMistral';
import CondominioParqueBalmaceda from './components/Propiedades/CondominioParqueBalmaceda';
import CondominioCumbresdelRetiro from './components/Propiedades/CondominioCumbresdelretiro';
import PredioPedregal from './components/Propiedades/Terrenos/PredioPedregal';
import Propiedad from './components/Propiedades/Propiedad';
/* // Preloader
const Preloader = React.lazy(() => import("./components/layouts/Preloader")); */

// Home Pages
const Hometwo = React.lazy(() => import("./components/pages/Hometwo"));
// Blog
const Bloggrid = React.lazy(() => import("./components/pages/Bloggrid"));
const Bloglist = React.lazy(() => import("./components/pages/Bloglist"));
const Blogsingle = React.lazy(() => import("./components/pages/Blogsingle"));
// Pages
const About = React.lazy(() => import("./components/pages/About"));
const Services = React.lazy(() => import("./components/pages/Services"));
const Faq = React.lazy(() => import("./components/pages/Faq"));
const Pricing = React.lazy(() => import("./components/pages/Pricing"));
const Contact = React.lazy(() => import("./components/pages/Contact"));
const Comingsoon = React.lazy(() => import("./components/pages/Comingsoon"));
const Error = React.lazy(() => import("./components/pages/Error"));
const Login = React.lazy(() => import("./components/pages/Login"));
const Register = React.lazy(() => import("./components/pages/Register"));
const Legal = React.lazy(() => import("./components/pages/Legal"));
// Listings
const Listinggrid = React.lazy(() => import("./components/pages/Listinggrid"));
const ListinggridTerr = React.lazy(() => import("./components/pages/ListinggridTerr"));
const PropiedadesEnArriendo = React.lazy(() => import("./components/pages/ListinggridArriendo"));
const SubdivisionesPrediales = React.lazy(() => import("./components/pages/ListinggridSuvdivisiones"));




const Listinglist = React.lazy(() => import("./components/pages/Listinglist"));
const Listingmap = React.lazy(() => import("./components/pages/Listingmap"));
const Listingdetailsone = React.lazy(() => import("./components/pages/Listingdetailsone"));
const Listingdetailstwo = React.lazy(() => import("./components/pages/Listingdetailstwo"));
const Listingdetailsthree = React.lazy(() => import("./components/pages/Listingdetailsthree"));
const Submitlisting = React.lazy(() => import("./components/pages/Submitlisting"));
const Comparelistings = React.lazy(() => import("./components/pages/Comparelistings"));
// Agents
const Agentarchive = React.lazy(() => import("./components/pages/Agentarchive"));
const Agentdetails = React.lazy(() => import("./components/pages/Agentdetails"));
const Profile = React.lazy(() => import("./components/pages/Profile"));
const Profilelistings = React.lazy(() => import("./components/pages/Profilelistings"));
const Profilesavedlistings = React.lazy(() => import("./components/pages/Profilesavedlistings"));
// Agency
const Agencyarchive = React.lazy(() => import("./components/pages/Agencyarchive"));
const Agencydetails = React.lazy(() => import("./components/pages/Agencydetails"));
// Propiedades


function App() {
  return (
    <Router>
      <Suspense fallback={<div></div>}>
  {/*       <Preloader /> */}
        <Switch>
          {/* Homepages */}
          <Route exact path="/" component={Hometwo} />
          {/* Pages */}
          <Route path="/nosotros" component={About} />
          <Route path="/servicios" component={Services} />
          <Route path="/faq" component={Faq} />
          <Route path="/pricing" component={Pricing} />
          <Route path="/contacto" component={Contact} />
          <Route path="/coming-soon" component={Comingsoon} />
          <Route path="/error-404" component={Error} />
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route path="/legal" component={Legal} />
          {/* Listings */}



          <Route path="/propiedadesenventa" component={Listinggrid} />
          <Route path="/propiedadesenarriendo" component={PropiedadesEnArriendo} />
          <Route path="/terrenosenventa" component={ListinggridTerr} />
          <Route path="/suvdivisiones" component={SubdivisionesPrediales} />


          <Route path="/listing-details-v1" component={Listingdetailsone} />
          <Route path="/submit-listing" component={Submitlisting} />
          {/* Agents */}
          <Route path="/profile" component={Profile} />
          <Route path="/profile-listings" component={Profilelistings} />
          <Route path="/profile-saved-listings" component={Profilesavedlistings} />
          {/* Agency */}
          <Route path="/agency-archive" component={Agencyarchive} />
          <Route path="/agency-details" component={Agencydetails} />
         {/*  Propiedades */}
         <Route path="/calle-villagran" component={CalleVillagran} />
         <Route path="/villalasislas" component={VillaLasIslas} />
         <Route path="/casa-sor-vicenta" component={SorVicenta} />
         <Route path="/casa-santa-ana" component={SantaAna} />
         <Route path="/haciendalascruces" component={Haciendalascruces} />
         <Route path="/lasorquideas" component={LasOrquideas} />
         <Route path="/casa-esquina-sor-vicenta" component={CasaEsquinaSorVicenta} />
         <Route path="/departamento-homestudio" component={HomeStudio} />
         <Route path="/villaparquesorvicenta" component={SorVicenta2} />
         <Route path="/caminoantuco" component={CaminoAntuco} />
         <Route path="/condominiocordillera" component={CondominioCordillera} />
         <Route path="/departmento-santa-ana" component={SantaAna2} />
         <Route path="/casa-amoblada-santa-ana" component={SantaAna3} />
         <Route path="/casa-mirador-oriente" component={CasaMiradorOriente} />
         <Route path="/bodegas" component={Bodegas} />
         <Route path="/departamento-arriendo" component={DepartamentoLientur} />
         <Route path="/galilea" component={galilea} />
         <Route path="/quillayes" component={quillayes} />
         <Route path="/casa-villagenesis" component={villagenesis} />
         <Route path="/casa-gabrielamistral" component={gabrielamistral} />
         <Route path="/depto-torrevaldivia" component={torrevaldivia} />
         <Route path="/villa-claudio-arrau" component={ClaudioArrau} />
         <Route path="/villa-retiro-sur" component={VillaRetiroSur} />
         <Route path="/villa-espana" component={VillaEspana} />
         <Route path="/villa-antuco" component={VillaAntuco} />
         <Route path="/Ohiggins" component={Ohiggins} />       
         <Route path="/casatodoslossantos" component={casatodoslossantos} />
         <Route path="/casatodoslossantos" component={casatodoslossantos} />
         <Route path="/departamento-laguna-verde" component={LagunaVerde} />
         <Route path="/departamento-la-castellana" component={Lacastellana} />
         <Route path="/departamento-los-quillayes" component={LosQuillayes} />
         <Route path="/departamento-los-quillayes-2" component={LosQuillayes2} />
         <Route path="/calle-mendoza" component={CalleMendoza} />
         <Route path="/condominio-gabrielamistral" component={CondoinioGabrielaMistral} />
         <Route path="/condominio-parquebalmaceda" component={CondominioParqueBalmaceda} />
         <Route path="/condominio-cumbresdelretiro" component={CondominioCumbresdelRetiro} />
         <Route path="/propiedad/:id" component={Propiedad} />
          {/*  Terrenos */}
          <Route path="/Chacayal" component={Chacayal} />
          <Route path="/cerrocolorado" component={CerroColorado} />
          <Route path="/elperal" component={ElPeral} />
          <Route path="/sectorcarrizal" component={Carrizal} />
          <Route path="/caminocerrocolorado" component={CaminoCerroColorado} />
          <Route path="/santaadriana" component={SantaAdriana} />
          <Route path="/losrobles" component={LosRobles} />
          <Route path="/pedrioPedregal" component={PredioPedregal} />

        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
