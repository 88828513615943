import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

const bannerpost = [
    {
        img: 'assets/img/listing-single/condominiosantaana2/2.jpg',
    },
    {
        img: 'assets/img/listing-single/condominiosantaana2/3.jpg',
    },
    {
        img: 'assets/img/listing-single/condominiosantaana2/4.jpg',
    },
    {
        img: 'assets/img/listing-single/condominiosantaana2/5.jpg',
    },
    {
        img: 'assets/img/listing-single/condominiosantaana2/7.jpg',
    },
    {
        img: 'assets/img/listing-single/condominiosantaana2/8.jpg',
    },
    {
        img: 'assets/img/listing-single/condominiosantaana2/9.jpg',
    },
    {
        img: 'assets/img/listing-single/condominiosantaana2/10.jpg',
    }
]

class Banner extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }
    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }
    render() {
        const settings = {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: false,
            fade: true,
        }
        return (
            <div className="banner banner-2 slider-no-padding">
                <div className="banner-item">
                    <Slider className="banner-slider" ref={c => (this.slider = c)} {...settings}>
                        {bannerpost.map((item, i) => (
                            <div key={i}>
                                <div className="banner-inner bg-center dark-overlay" style={{ backgroundImage: "url(" + item.img + ")" }} />
                            </div>
                        ))}
                    </Slider>

                    
                    <div className="acr-listing-details">
                        <div className="acr-listing-section">
                            <div className="acr-listing-section-body">
                                <div className="acr-listing-section-price">
                                    <span>Tipo: Arriendo</span>
                                    <h3>Valor: $520.000</h3>
                                </div>
                            </div>
                        </div>
                        <div className="acr-listing-section">
                            <div className="acr-listing-section-body">
                                <h4> Direccion: Condominio Santa Ana</h4>
                                <div className="acr-listing-icons">
                                    <div className="acr-listing-icon">
                                        <i className="flaticon-bedroom" />
                                        <span className="acr-listing-icon-value">3</span>
                                        <span>Dormitorios</span>
                                    </div>
                                    <div className="acr-listing-icon">
                                        <i className="flaticon-bathroom" />
                                        <span className="acr-listing-icon-value">2</span>
                                        <span>Baños</span>
                                    </div>
                                    <div className="acr-listing-icon">
                                        <i className="flaticon-ruler" />
                                        <span className="acr-listing-icon-value"></span>
                                        <span>M2</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                       
                    </div>
                </div>
                <div className="acr-arrows">
                    <i className="slider-prev fas fa-arrow-left slick-arrow" onClick={this.previous} />
                    <i className="slider-next fas fa-arrow-right slick-arrow" onClick={this.next} />
                </div>
            </div>
        );
    }
}

export default Banner;